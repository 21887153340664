export const calcNewMsg = (comments: { data: any[] }) => {
  let unreadedComments = 0;
  if (
    comments &&
    comments.hasOwnProperty('data') &&
    comments?.data?.length > 0
  ) {
    unreadedComments = comments?.data?.filter(
      (comment: any) => !comment?.hasOwnProperty('exposed_at')
    )?.length;
  }
  return unreadedComments;
};
