import { useMutation } from '@tanstack/react-query';
import { Alert, Button, Card, Input, Spin, Typography, Image } from 'antd';
import { usePostData } from 'api/usePostData';
import { useState, useEffect, FC, useRef } from 'react';
import styles from './styles.module.scss';

const { Title, Text } = Typography;

interface Option {
  text: string;
  letter: string;
  response: string;
}

interface Question {
  question: string;
  options: Option[];
}

interface QuizData {
  questions: Question[];
  finalMessage: string;
  timerMessage: string;
  code: string;
}

export const PhotoQuiz: FC<{
  data: any;
  token: string;
  pos: any;
}> = ({ data, token, pos }) => {
  const [questions, setQuestions] = useState<Question[]>([]);
  const [finalMessage, setFinalMessage] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const [timerMsg, setTimerMsg] = useState<string>('');
  const [answers, setAnswers] = useState<{ [key: number]: string }>({});
  const [showContent, setShowContent] = useState<boolean>(true);

  const myRef = useRef<HTMLDivElement>(null);

  const { mutate } = useMutation({
    mutationFn: usePostData,
    retry: 1,
  });

  useEffect(() => {
    if (
      Object.keys(answers)?.length > 0 &&
      questions?.length > 0 &&
      Object.keys(answers)?.length === questions?.length
    ) {
      setShowContent(false);
      const dataToBack = {
        extra_data: { finalCode: Object.values(answers).join(' - '), pos },
        id: '58acd114-5a2b-48d8-99a2-4ca3bc987417',
        internal: true,
      };

      mutate({
        data: dataToBack,
        token,
        otherProps: `comments`,
        method: 'PUT',
      });
      if (myRef?.current) {
        setTimeout(() => {
          myRef.current?.scrollIntoView({ behavior: 'smooth' });
        }, 300);
      }
    }
  }, [answers, mutate, pos, questions, token]);

  useEffect(() => {
    if (
      data &&
      Array.isArray(data?.data) &&
      data.data.length > 0 &&
      data.data[0]?.extra_data
    ) {
      const inData: QuizData = data.data[0].extra_data;
      setTimerMsg(inData?.timerMessage || '');
      setQuestions(inData?.questions || []);
      setFinalMessage(inData.finalMessage);
      setCode(inData.code);
    }
  }, [data]);

  const goToEl = (id: string) => {
    const el = document.querySelector(`#${id}`);
    if (!el) return;
    setTimeout(() => {
      el.scrollIntoView({ behavior: 'smooth' });
    }, 200);
  };

  const handleAnswer = (questionIndex: number, letter: string) => {
    setAnswers((prevAnswers) => ({ ...prevAnswers, [questionIndex]: letter }));
    goToEl(`id-by-letter-${letter}`);
  };

  if (!questions?.length) {
    return <></>;
  }

  return (
    <div style={{ maxWidth: 600, margin: 'auto', padding: '20px' }}>
      {showContent ? (
        questions.length > 0 ? (
          questions.map((q, qIndex) => (
            <Card
              key={qIndex}
              style={{
                marginBottom: 20,
                boxShadow: '0 0 10px rgba(0,0,0,0.1)',
              }}
            >
              <Title level={4}>{q.question}</Title>
              {Array.isArray(q?.options) &&
                q.options.length &&
                q.options.map((option, index) => (
                  <div
                    key={index + option.letter}
                    id={`id-by-letter-${option.letter}`}
                    style={{ marginBottom: 10 }}
                  >
                    {typeof option?.text === 'string' &&
                    option.text?.startsWith('https://files.test') ? (
                      <Image
                        onClick={() => handleAnswer(qIndex, option.letter)}
                        width={200}
                        src={option.text}
                      />
                    ) : (
                      <Button
                        style={{ height: 'auto', textWrap: 'balance' }}
                        onClick={() => handleAnswer(qIndex, option.letter)}
                        type={
                          answers[qIndex] === option.letter
                            ? 'primary'
                            : 'default'
                        }
                        block
                      >
                        <span className={styles.letter}>{option.letter}</span>
                        {option.text}
                      </Button>
                    )}

                    {answers[qIndex] === option.letter && (
                      <Text
                        type="secondary"
                        style={{
                          display: 'block',
                          marginTop: 5,
                          textWrap: 'balance',
                          textAlign: 'center',
                        }}
                      >
                        {option.response}
                      </Text>
                    )}
                  </div>
                ))}
            </Card>
          ))
        ) : (
          <Spin tip="Loading..." size="large" />
        )
      ) : (
        <Alert
          message={<span style={{ fontFamily: 'sans-serif' }}>{timerMsg}</span>}
          type="info"
          showIcon
        />
      )}

      {Object.keys(answers).length === questions.length && (
        <Card style={{ marginTop: 20, textAlign: 'center' }}>
          <Title level={3} type="success">
            {finalMessage}
          </Title>
          <Title style={{ textWrap: 'balance' }} level={5} type="secondary">
            {code}
          </Title>
          <div
            style={{
              display: 'flex',
              gap: 10,
              marginTop: 20,
              marginBottom: 20,
              justifyContent: 'center',
            }}
          >
            <Input.TextArea
              style={{ flex: 1, textAlign: 'center' }}
              value="A - E - L - N - S"
              readOnly
              autoSize={{ minRows: 1, maxRows: 1 }}
            />
          </div>
        </Card>
      )}
      <div ref={myRef} />
    </div>
  );
};
