import { FunctionComponent, useState, useEffect, useMemo } from 'react';
import { Badge, Tabs, TabsProps } from 'antd';
import styles from './styles.module.scss';
import { TabJobs } from './components/TabJobs/tabJobs';
import { TabJobsMap } from './components/TabJobsMap/tabJobsMap';
import { TabRequests } from './components/TabRequests/tabRequests';
// import { TabForReviewedJobs } from './components/TabForReviewedJobs/tabForReviewedJobs';
// import { TabForReviewedOrders } from './components/TabForReviewedOrders/tabForReviewedOrders';
import { APP_CONSTANS } from '../../../../../../../shared/constants';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import { TabAddresses } from './components/TabAddresses/tabAddresses';
import { TabAccountList } from './components/TabAccountList/tabAccountList';
import { TabOrders } from './components/TabOrders/tabOrders';
import { TabContentList } from './components/TabContentList/tabContentList';
import { Comments } from 'components/ui_components/Comments';
import { TabCommunication } from './components/TabCommuncation';
import { TabPurchaseMethods } from './components/TabPurchaseMethods';
import { TabPaymentMethods } from './components/TabPaymentMethods';
import { useQuery } from '@tanstack/react-query';
import { queryNames } from 'api/queryNames';
import { useSelector } from 'react-redux';
import useGetData from 'api/useGetData';
import { calcNewMsg } from 'shared/utils/calculateNewComments';

export const UserTables: FunctionComponent<any> = ({
  listOfAccounts,
  type,
  mainAddressId,
  quantityByStatusesJobs,
  refetchUser,
  // refetchUserAddress,
  id,
  allJobs,
  isLoadingJobs,
  defaultPurchaseMethod,
}) => {
  const token = useSelector((state: any) => state.auth.token);
  const getAllData = true;

  const [choosenRequestStatuses, setChoosenRequestStatuses] = useState<
    CheckboxValueType[]
  >([]);
  const [items, setItems] = useState<any>(null);
  const [notesCount, setNotesCount] = useState<number>(0);

  // Comments for Photographer, Inner by props = 'Object Type', 'Object id'
  const { data: dataComments } = useQuery({
    queryKey: [
      `${queryNames.COMMENTS}/${'user'}/${id}`,
      `comments_${'user'}`,
      id,
    ],
    meta: {
      token,
      getAllData,
      additionalSearchParams: `?internal=true&relative=true`,
    },
    queryFn: useGetData,
    enabled: !!id,
    staleTime: 60000,
    refetchInterval: 60000,
  });

  useEffect(() => {
    if (setNotesCount && dataComments?.data?.length) {
      const newMsgs = calcNewMsg({ data: dataComments.data });
      setNotesCount(newMsgs);
    }
  }, [dataComments, setNotesCount]);

  useEffect(() => {
    setChoosenRequestStatuses([
      APP_CONSTANS.REQUESTS_STATUSES.APPROVED,
      APP_CONSTANS.REQUESTS_STATUSES.SUBMITTED,
      APP_CONSTANS.REQUESTS_STATUSES.REJECTED,
      APP_CONSTANS.REQUESTS_STATUSES.FAILED,
      APP_CONSTANS.REQUESTS_STATUSES.DELETED,
    ]);
  }, []);

  const tabJobs = useMemo(() => {
    return {
      key: 'jobs',
      label: `Jobs`,
      children: <TabJobs id={id} />,
    };
  }, [id]);

  const tabOrders = useMemo(() => {
    return {
      key: 'orders',
      label: `Orders`,
      children: <TabOrders id={id} />,
    };
  }, [id]);

  const tabJobsMap = useMemo(() => {
    return {
      key: 'map',
      label: `Jobs Map`,
      children: (
        <TabJobsMap
          jobs={allJobs && allJobs.hasOwnProperty('data') && allJobs.data}
          quantityByStatusesJobs={quantityByStatusesJobs}
          userId={id}
        />
      ),
    };
  }, [allJobs, id, quantityByStatusesJobs]);

  // const tabReviewedFulfilments = {
  //   key: 'reviewedFulfilments',
  //   label: `Reviewed Fulfilments`,
  //   children: <TabForReviewedJobs />,
  // };

  // const tabReviewedOrders = {
  //   key: 'reviewedOrders',
  //   label: `Reviewed Orders`,
  //   children: <TabForReviewedOrders />,
  // };

  const tabRequests = useMemo(() => {
    return {
      key: 'requests',
      label: `Requests`,
      children: <TabRequests id={id} statuses={choosenRequestStatuses} />,
    };
  }, [choosenRequestStatuses, id]);

  const tabContentList = useMemo(() => {
    return {
      key: 'contentList',
      label: `Content List`,
      children: <TabContentList id={id} />,
    };
  }, [id]);

  const tabPaymentMethod = useMemo(() => {
    return {
      key: 'paymentMethods',
      label: `Payment Methods`,
      children: <TabPaymentMethods userId={id} />,
    };
  }, [id]);

  const tabsForAllTypes = useMemo(() => {
    return [
      {
        key: 'accounts',
        label: `Accounts`,
        children: <TabAccountList userId={id} />,
      },
      {
        key: 'address',
        label: `Addresses`,
        children: (
          <TabAddresses
            userId={id}
            mainAddressId={mainAddressId}
            refetchUser={refetchUser}
            // refetchUserAddress={refetchUserAddress}
          />
        ),
      },
    ];
  }, [id, mainAddressId, refetchUser]);

  const tabsForPhType = useMemo(() => {
    return [
      {
        key: 'notes',
        label: (
          <Badge count={notesCount} dot offset={[5, 3]}>
            Notes
          </Badge>
        ),
        children: (
          <Comments
            inner={true}
            isInternal={true}
            inDrawer={true}
            objectType="user"
            objectId={id}
            fixedHeight={false}
            customClassname={styles.notes}
            cancelScrollBottom={true}
            setNotesCount={setNotesCount}
          />
        ),
      },
      {
        key: 'communication',
        label: `Communication`,
        children: <TabCommunication userId={id} />,
      },
      {
        key: 'purchaseMethod',
        label: `Purchase Methods`,
        children: (
          <TabPurchaseMethods
            defaultPurchaseMethod={defaultPurchaseMethod}
            userId={id}
          />
        ),
      },
    ] as TabsProps['items'];
  }, [defaultPurchaseMethod, id, notesCount, setNotesCount]);

  useEffect(() => {
    const newItems: TabsProps['items'] = [];
    if (type === 'photographer' || type === 'superuser') {
      tabJobs && newItems.push(tabJobs);
    }
    if (type === 'customer' || type === 'superuser') {
      tabOrders && newItems.push(tabOrders);
      tabPaymentMethod && newItems.push(tabPaymentMethod);
    }
    if (
      (type === 'photographer' && !isLoadingJobs) ||
      (type === 'superuser' && !isLoadingJobs)
    ) {
      tabJobsMap && newItems.push(tabJobsMap);
    }
    if (type === 'editor' || type === 'superuser') {
      // newItems.push(tabReviewedFulfilments);
      // newItems.push(tabReviewedOrders);
    }
    if ((type === 'photographer' || type === 'superuser') && id) {
      tabRequests && newItems.push(tabRequests);
      tabContentList && newItems.push(tabContentList);
    }
    if (type === 'photographer') {
      tabsForPhType && newItems.push(...tabsForPhType);
    }
    const allTabs = newItems.concat(tabsForAllTypes);
    setItems(allTabs);
  }, [
    type,
    id,
    isLoadingJobs,
    allJobs,
    listOfAccounts,
    mainAddressId,
    tabsForAllTypes,
    tabJobs,
    tabOrders,
    tabPaymentMethod,
    tabJobsMap,
    tabRequests,
    tabContentList,
    tabsForPhType,
  ]);

  return items ? (
    <section>
      <Tabs
        className={styles.tabs}
        items={items}
        destroyInactiveTabPane={true}
      />
    </section>
  ) : (
    <></>
  );
};
