export enum queryNames {
  AUTH_getCurrentUser = 'auth/user',
  AUTH_logout = 'auth/logout',
  AUTH_virtualLogout = 'auth/virtual_logout',

  ACCOUNTS_ALL = 'accounts',
  ACCOUNTS_getAccountById = 'accounts/get',
  ACCOUNTS_getAccountPreferencesById = 'account_preferences',
  ACCOUNTS_getAddressById = 'user_addresses/get',
  ACCOUNTS_getSubscriptions = 'subscriptions',
  ACCOUNTS_getIndustries = 'industries',
  ACCOUNTS_customerInvoicedSum = 'customer/payment_info',
  ACCOUNTS_LIST = 'all_accounts',
  ACCOUNT_ADDRESS = 'account_addresses',
  ACCOUNT_ADDRESS_GET = 'account_addresses/get',

  PENDING_REQUEST = 'signup/lookup_requests',
  PENDING_INVITES = 'invite/lookup_requests',

  USERS_ALL = 'users',
  USERS_getUserPreferencesById = 'user_preferences',
  USERS_getUserById = 'users/get',
  USERS_getAddressById = 'user_addresses',
  USERS_userAccounts = 'accounts',
  USERS_getUserRequests = 'user_requests',
  USER_getUrserRequestById = 'user_requests/get',
  USER_getUrserRequestHeader = 'user_requests/header',

  USER_ACCOUNTS = 'user_accounts',
  USERS_API_KEYS = 'api_keys',

  REFERENCE_getCountries = 'countries',
  REFERENCE_getCountryRegions = 'country_regions',

  FULFILMENT_CONTENTS = 'fulfilment_contents',
  FULFILMENT_CONTENTS_GET = 'fulfilment_contents/get',
  FULFILMENT_CONTENTS_COUNT = 'fulfilment_contents/count',
  CHECK_FULFILMENT_CONTENT = 'engine/check_content',
  CHECK_FULFILMENT_CONTENT_BULK = 'engine/check_content_bulk',

  FULFILMENTS = 'fulfilments',
  FULFILMENTS_ALL = 'all_fulfilments',
  FINANCE_COMPLETED_BUT_NOT_PAID_JOBS = 'fulfilments',
  FINANCE_JOBS_TO_PAID = 'fulfilments',
  FULFILMENTS_BY_ORDER = 'fulfilments',
  FULFILMENT_BY_ID = 'fulfilments/get',
  FULFILMENT_REQUESTS_HEADER = 'fulfilment_requests/header',
  FULFILMENT_REQUESTS_GET_BY_ID = 'fulfilment_requests/get',
  FULFILMENT_REQUESTS = 'fulfilment_requests',
  FULFILMENT_COUNT = 'fulfilments/count',
  FULFILMENT_CLEANER_CHECK = 'cleaner/check_fulfilment',
  FULFILMENT_CLEANER_SOFT_DELETE = 'cleaner/soft_delete_fulfilment',
  FULFILMENT_IN_REVIEW = 'engine/fulfilment_review_list',
  FULFILMENT_STATES = 'fulfilment_states',

  ORDERS = 'orders',
  ORDER_BY_ID = 'orders/get',
  ORDER_COUNT = 'orders/count',
  ORDER_CLEANER_CHECK = 'cleaner/check_order',
  ORDER_STATES = 'order_states',
  ORDER_IN_REVIEW = 'engine/order_review_list',
  ORDER_OWNERSHIP = 'engine/order_ownership',
  ORDER_PAYMET_METHOD = 'engine/order_change_method',
  ORDER_LOCATION = 'customer/order_location',
  ORDER_CONFIGURE = 'customer/order_configure',
  ORDER_CREATE = 'customer/order_create',
  ORDER_CREATE_CSV = 'customer/import',
  ORDER_CALCULATE = 'customer/order_calculate',
  LOCK_LIST = 'office/object_lock',

  CUSTOMER_GET_DESCRIPTION = 'customer/order/description',
  TEMPLATE_GET_DESCRIPTION = 'customer/template/description',

  TEMPLATES = 'templates',
  TEMPLATE_BY_ID = 'templates/get',
  TEMPLATE_UPDATE = 'customer/template_update',
  TEMPLATE_CREATE = 'customer/template_create',

  COMMENTS_FOR_JOBS = 'office/comment/fulfilment',
  COMMENTS_FOR_ORDERS = 'office/comment/order',
  COMMENTS = 'office/comment',

  COMPLICATIONS = 'complications',
  COMPLICATION_CHILDREN = 'complication_children',
  COMPLICATION_LINKS = 'complication_links',

  ARCHIVE = 'v2/archive/prepare',
  REPLACE_CONTENTS = 'v2/replace/archive',

  SYSTEM_PREFERENCES = 'system_preferences',
  SYSTEM_PREFERENCES_OPTIONS = 'preference_options',
  SystemPreferencesBulkPost = 'system_preferences/bulk',
  Preferences = 'preferences',
  PreferenceById = 'preferences/get',
  PreferenceOptions = 'preference_options',
  Purchases = 'purchases',
  PurchaseCreate = 'office/purchase/create',
  PurchasesBulkCreate = 'office/purchase/compose',
  PurchasePayout = 'office/purchase/payout',
  PurchasePayoutBulk = 'office/purchase/payout_bulk',
  PurchaseTransactions = 'purchase_transactions',
  PurchaseTransactionsById = 'purchase_transactions/get',
  PurchaseOperations = 'purchase_operations',
  PurchaseOperationsById = 'purchase_operations/get',
  PaymentOperations = 'payment_operations',
  PaymentOperationsById = 'payment_operations/get',
  PurchasesById = 'purchases/get',
  PurchaseItems = 'purchase_items',
  PurchaseItemsById = 'purchase_items/get',
  PurchaseTargets = 'purchase_targets',
  ProviderTargets = 'provider_targets',

  ColumnsState = 'internal/table_info/',
  DefaultLimit = 'internal/table_info/default_limit',

  INVOICES = 'invoices',
  INVOICE_ITEMS = 'invoice_items',
  INVOICES_BY_ID = 'invoices/get',
  INVOICE_LINES = 'invoice_lines',
  INVOICE_LINES_BY_ID = 'invoice_lines/get',
  INVOICE_CHARGE = 'office/invoice/charge',
  INVOICE_CHARGE_BULK = 'office/invoice/charge_bulk',
  INVOICE_CREATE = 'office/invoice/create',
  INVOICE_REFUND = 'office/invoice/refund',
  INVOICE_UPDATE = 'office/invoice/update',
  INVOICE_CANCEL = 'office/invoice/cancel',
  INVOICE_METHOD = 'office/invoice/method',

  ORDERS_ALL = 'all_orders',
  USERS_ALL_DATA = 'all_users',

  AccountCheckingRelations = 'cleaner/check_account',

  GEONAMES = 'geonames',
  CountryTimezones = 'country_timezones',
  Currencies = 'currencies',
  OrderStates = 'order_states',
  OrderPaymentStates = 'order_payment_states',
  FulfilmentStates = 'fulfilment_states',
  ContentStates = 'content_states',
  RequestStates = 'request_states',
  PaymentStates = 'payment_statuses',
  PurchaseStates = 'purchase_states',
  PurchaseProviders = 'purchase_providers',
  PurchaseMethods = 'purchase_methods',
  PayoutStates = 'payout_statuses',
  InvoiceStates = 'invoice_states',

  Countries = 'countries',
  CountriesById = 'countries/get',
  CountryRegions = 'country_regions',
  CountryRegionsById = 'country_regions/get',
  GeonamesById = 'geonames/get',
  CountryTimezonesById = 'country_timezones/get',
  CurrenciesById = 'currencies/get',
  OrderStatesById = 'order_states/get',
  OrderPaymentStatesById = 'order_payment_states/get',
  JobStateById = 'fulfilment_states/get',
  ContentStateById = 'content_states/get',
  RequestStateById = 'request_states/get',
  PaymentStateById = 'payment_statuses/get',
  PurchaseStateById = 'purchase_states/get',
  PurchaseProviderById = 'purchase_providers/get',
  PurchaseMethodsById = 'purchase_methods/get',
  PurchaseTargetById = 'purchase_targets/get',
  PayoutStateById = 'payout_statuses/get',
  PaymentMethodById = 'payment_methods/get',
  PaymentProviderById = 'payment_providers/get',
  PaymentTransactionById = 'payment_transactions/get',
  InvoiceStateById = 'invoice_states/get',

  CompletedButNotPaidJobs = 'fulfilments',

  UserCheckingRelations = 'cleaner/check_user',

  CheckPoints = 'check_points',

  PaymentTransactions = 'payment_transactions',
  PaymentMethods = 'payment_methods',
  PaymentProvider = 'payment_providers',

  PAYMENTS_CANCEL = 'payments/cancel',
  PAYMENTS_CHARGE = 'payments/charge',
  PAYMENTS_CHECK = 'payments/check',
  PAYMENTS_REFUND = 'payments/refund',
  PAYMENTS_RETRY = 'payments/retry',

  INDUSTRIES = 'industries',
  INDUSTRIES_CHILDREN = 'industry_children',
  INDUSTRIES_COMPLICATIONS = 'industry_complications',

  SUBSCRIPTIONS = 'subscriptions',

  HISTORIES = 'histories',

  BASE_PRICES = 'base_prices',

  LOGGING_FRONT = 'internal/logging/admin_ui',

  CREDENTIALS_BRAINTREE = 'credentials/braintree',
  CREDENTIALS_TANGO = 'credentials/tango',

  // SOFT DELETE
  CLEANER_SOFT_DELETE_INVOICE = 'cleaner/soft_delete_invoice',
  CLEANER_SOFT_DELETE_CONTENT_BULK = 'cleaner/soft_delete_content_bulk',
  CLEANER_SOFT_DELETE_FULFILMENT = 'cleaner/soft_delete_fulfilment',
  CLEANER_SOFT_DELETE_ORDER = 'cleaner/soft_delete_order',
  CLEANER_SOFT_DELETE_ACCOUNT_ADDRESS = 'cleaner/soft_delete_account_address',
  CLEANER_SOFT_DELETE_ACCOUNT = 'cleaner/soft_delete_account',
  CLEANER_SOFT_DELETE_USER = 'cleaner/soft_delete_user',
  CLEANER_SOFT_DELETE_USER_ADDRESS = 'cleaner/soft_delete_address',
  CLEANER_SOFT_DELETE_PAYMENT_METHOD = 'cleaner/soft_delete_payment_method',
  CLEANER_SOFT_DELETE_PURCHASE_METHOD = 'cleaner/soft_delete_purchase_method',
  TEMPLATES_SOFT_DELETE = 'templates/soft',

  // HARD DELETE
  CLEANER_HARD_DELETE_INVOICE = 'cleaner/hard_delete_invoice',
  CLEANER_HARD_DELETE_FULFILMENT = 'cleaner/hard_delete_fulfilment',
  CLEANER_HARD_DELETE_ORDER = 'cleaner/hard_delete_order',
  CLEANER_HARD_DELETE_ACCOUNT_ADDRESS = 'cleaner/hard_delete_account_address',
  CLEANER_HARD_DELETE_USER_ACCOUNT = 'cleaner/hard_delete_user_account',
  CLEANER_HARD_DELETE_ACCOUNT = 'cleaner/hard_delete_account',
  CLEANER_HARD_DELETE_USER = 'cleaner/hard_delete_user',
  CLEANER_HARD_DELETE_USER_ADDRESS = 'cleaner/hard_delete_address',
  TEMPLATE_HARD_DELETE = 'templates/hard',
  COMPLICATION_LINKS_HARD_DELETE = 'complication_links/hard',
  INDUSTRY_CHILDREN_HARD_DELETE = 'industry_children/hard',
  INDUSTRY_COMPLICATIONS_HARD_DELETE = 'industry_complications/hard',

  // RESTORE
  CLEANER_RESTORE_INVOICE = 'cleaner/restore_invoice',
  CLEANER_RESTORE_CONTENT_BULK = 'cleaner/restore_content_bulk',
  CLEANER_RESTORE_FULFILMENT = 'cleaner/restore_fulfilment',
  CLEANER_RESTORE_ORDER = 'cleaner/restore_order',
  CLEANER_RESTORE_ACCOUNT = 'cleaner/restore_account',
  CLEANER_RESTORE_USER = 'cleaner/restore_user',
  CLEANER_RESTORE_PAYMENT_METHOD = 'cleaner/restore_payment_method',
  CLEANER_RESTORE_USER_ADDRESS = 'cleaner/restore_address',
  CLEANER_RESTORE_ACCOUNT_ADDRESS = 'cleaner/restore_account_address',
  CLEANER_RESTORE_PURCHASE_METHOD = 'cleaner/restore_purchase_method',
  TEMPLATE_RESTORE = 'templates/restore',
  SUBSCRIPTIONS_RESTORE = 'subscriptions/restore',
  INDUSTRY_RESTORE = 'industries/restore',
  PREFERENCE_OPTIONS_RESTORE = 'preference_options/restore',
  PREFERENCES_RESTORE = 'preferences/restore',
  COMPLICATIONS_RESTORE = 'complications/restore',
  COMPLICATION_CHILDREN_RESTORE = 'complication_children/restore',

  CLEANER_ACTIVATE_USER = 'cleaner/activate_user',
  CLEANER_SUSPEND_USER = 'cleaner/suspend_user',

  ABORT_USER = 'auth/abort',
}
