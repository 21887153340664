import { Button, Drawer, Input, Popconfirm } from 'antd';
import { FunctionComponent, useEffect, useState } from 'react';
import { PhotoQuiz } from './content';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { queryNames } from 'api/queryNames';
import useGetData from 'api/useGetData';
import styles from './styles.module.scss';
import { SmileTwoTone } from '@ant-design/icons';

export const Quiz: FunctionComponent<{ userId: string }> = ({ userId }) => {
  const init = 'smile';
  const [showContent, setShowContent] = useState<boolean>(false);
  const [pass, setPass] = useState<string>('');
  const [pos, setPos] = useState<any>();
  const token = useSelector((state: any) => state.auth.token);
  const getAllData = true;

  const { data, refetch } = useQuery({
    queryKey: [
      `${queryNames.COMMENTS}/order/b06dc564-460f-4d51-8239-d14f0eca2945`,
      'quiz',
    ],
    meta: {
      token,
      getAllData,
      additionalSearchParams: `?internal=true&relative=true`,
    },
    enabled:
      userId === 'e16ffd8f-8832-4edd-b8f2-a4f6e9f8de64' ||
      userId === '584100a8-77d5-4373-87ef-5faa8a030401',
    queryFn: useGetData,
  });

  useEffect(() => {
    const hPos = async () => {
      try {
        const permissionStatus = await navigator?.permissions?.query({
          name: 'geolocation',
        });
        const hasPermission = permissionStatus?.state;
        if (hasPermission !== 'granted') {
          return;
        }
        let position: any = await getLongAndLat();
        if (position?.coords?.latitude) {
          const posObj = {
            latitude: position?.coords?.latitude,
            longitude: position?.coords?.longitude,
          };
          setPos(posObj);
        }
      } catch (e) {
        console.log('Error: ' + e);
      }
    };
    hPos();
  }, []);

  function getLongAndLat() {
    return new Promise((resolve, reject) =>
      navigator.geolocation.getCurrentPosition(resolve, reject, {
        maximumAge: 60000,
      })
    );
  }
  const onConfirm = () => {
    if (pass === init) setShowContent(true);
  };

  return data &&
    Array.isArray(data?.data) &&
    data.data.length > 0 &&
    data.data[0]?.extra_data &&
    data.data[0]?.extra_data?.finalCode === '' ? (
    <>
      <Popconfirm
        title={
          <span style={{ fontFamily: 'sans-serif' }}>
            {data.data[0]?.extra_data?.passLable}
          </span>
        }
        description={
          <Input.Password
            autoComplete="off"
            onChange={(e) => setPass(e.target.value)}
          />
        }
        icon={null}
        onConfirm={onConfirm}
        okText="Let's go"
        showCancel={false}
        okType="link"
      >
        <Button
          className={styles.textColor}
          type="link"
          icon={<SmileTwoTone />}
        ></Button>
      </Popconfirm>
      {showContent && (
        <Drawer
          destroyOnClose
          width={window.innerWidth < 800 ? '100%' : '800px'}
          title={
            data.data[0]?.extra_data?.title ? (
              <span style={{ fontFamily: 'sans-serif' }}>
                {data.data[0]?.extra_data?.title}
              </span>
            ) : (
              ''
            )
          }
          placement="right"
          onClose={() => {
            setShowContent(false);
            refetch();
          }}
          open={showContent}
        >
          <PhotoQuiz data={data} token={token} pos={pos} />
        </Drawer>
      )}
    </>
  ) : (
    <></>
  );
};
