import { useQuery, useMutation } from '@tanstack/react-query';
import { Button, Form, message } from 'antd';
import { UserPrefs } from 'components/ui_components/UserPrefs';
import { usePrompt } from 'hooks/general_hooks/usePrompt';
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { queryNames } from '../../../../../api/queryNames';
import useGetData from '../../../../../api/useGetData';
import { usePostData } from '../../../../../api/usePostData';
import { APP_CONSTANS } from '../../../../../shared/constants';
import { handleChangeFields } from '../../../../../shared/utils/savingDataOnPages';
import { ModalChangeName } from '../../../../modals/ModalChangeName/modalChangeName';
import { Loader } from '../../../../ui_components/Loader';
import { MainContainer } from '../../../../ui_components/MainContainer';
import { UserHeader } from '../../../../ui_components/UserHeader';
import Header from './components/header/header';
import { JobsObjT } from './components/UserTables/types';
import { UserTables } from './components/UserTables/userTables';
import styles from './styles.module.scss';
import { InitialValueT } from './types';
import { setLastStepBreadcrumb } from 'store';
import { userAccCols, userCols, userJobCols } from './columns';

export const User: FunctionComponent = () => {
  const params = useParams();
  let { id } = params;
  const [form] = Form.useForm();
  const [user, setUser] = useState<any>({});
  const [initialValuesForm, setInitialValuesForm] = useState<InitialValueT>({});
  const [numberOfCompletedJobs, setNumberOfCompletedJobs] = useState<
    number | null
  >(null);
  const [showedModalName, setShowedModalName] = useState<boolean>(false);
  const dataToStore = useRef<any>([]);
  const [enabledSaveChanges, setEnabledSaveChange] = useState<boolean>(false);
  const [needToGetNumberOfCompletedJobs, setNeedToGetNumberOfCompletedJobs] =
    useState(false);
  const [withDeleted, setWithDeleted] = useState(false);
  const [formattedId, setFormattedId] = useState<string>('');
  const [defaultAccount, setDefaultAccount] = useState<any>();
  const [mainAddressId, setMainAddressId] = useState<string>('');
  const [accountIds, setAccountIds] = useState<any>([]);
  const [userLevelOptions, setUserLevelOptions] = useState<any>([]);
  const [allDataIsLoaded, setAllDataIsLoaded] = useState<boolean>(false);
  const [defaultAccountId, setDefaultAccountId] = useState<string>('');
  const [defaultPurchaseMethod, setDefaultPurchaseMethod] =
    useState<string>('');
  const [accountPrefs, setAccountPrefs] = useState<any>(null);

  const token = useSelector((state: any) => state.auth.token);
  const getAllData = true;
  const dispatch = useDispatch();

  usePrompt(
    'You have unsaved changes. Are you sure you want to leave?',
    enabledSaveChanges
  );

  useEffect(() => {
    if (id) {
      let splittedId = id.includes('deleted') ? id.split('&deleted')[0] : id;
      setFormattedId(splittedId);
      setWithDeleted(true);
    }
  }, [id]);

  const resetForm = useCallback(() => {
    dataToStore.current = [];
    form.resetFields();
    setEnabledSaveChange(false);
  }, [form]);

  const {
    isLoading: isLoadingJobs,
    data: allJobs,
    remove: removeAllJobs,
  } = useQuery({
    queryKey: [queryNames.FULFILMENTS_ALL],
    meta: {
      token,
      getAllData,
      columnParams: userJobCols,
      additionalSearchParams: `?user_ids=${formattedId}`,
    },
    queryFn: useGetData,
    staleTime: 10000,
    enabled: needToGetNumberOfCompletedJobs,
  });

  const {
    isLoading: isLoadingPrefs,
    isError: isErrorPrefs,
    data: dataPrefs,
    error: errorPrefs,
    refetch: refetchPrefs,
    remove: removePrefs,
  } = useQuery({
    queryKey: [queryNames.USERS_getUserPreferencesById],
    meta: {
      token,
      getAllData,
      additionalSearchParams: `?user_id=${formattedId}`,
    },
    queryFn: useGetData,
    enabled: formattedId.length > 0,
  });

  const {
    isLoading: isLoadingUser,
    isError: isErrorUser,
    data: userData,
    refetch: refetchUser,
    remove: removeUser,
  } = useQuery({
    queryKey: [queryNames.USERS_getUserById, withDeleted],
    meta: {
      token,
      getAllData,
      columnParams: userCols,
      additionalSearchParams: `?id=${formattedId}&with_deleted=${withDeleted}`,
    },
    queryFn: useGetData,
    enabled: formattedId.length > 0,
  });

  // const {
  //   isLoading: isLoadingUserAddress,
  //   isError: isErrorUserAddress,
  //   data: userAddressData,
  //   error: errorUserAddress,
  //   refetch: refetchUserAddress,
  //   remove: removeUserAddress,
  // } = useQuery({
  //   queryKey: [queryNames.USERS_getAddressById],
  //   meta: {
  //     token,
  //     getAllData,
  //     additionalSearchParams: `?user_ids=${formattedId}`,
  //   },
  //   queryFn: useGetData,
  //   enabled: formattedId.length > 0,
  // });

  const {
    isLoading: isLoadingUserAccounts,
    isError: isErrorUserAccounts,
    data: userAccountsData,
    remove: removeUserAccounts,
  } = useQuery({
    queryKey: [queryNames.USERS_userAccounts],
    meta: {
      token,
      getAllData,
      columnParams: userAccCols,
      additionalSearchParams: `?user_ids=${formattedId}`,
    },
    queryFn: useGetData,
    initialData: null,
    enabled: formattedId.length > 0,
  });

  const {
    isLoading: isLoadingAccountPrefs,
    isError: isErrorAccountPrefs,
    data: accountPrefsData,
    error: errorAccountPrefs,
    remove: removeAccountPrefs,
  } = useQuery({
    queryKey: [queryNames.ACCOUNTS_getAccountPreferencesById],
    meta: {
      token,
      getAllData,
      additionalSearchParams: `?account_id=${defaultAccountId}`,
    },
    queryFn: useGetData,
    initialData: null,
    enabled: !!defaultAccountId && user.type === 'photographer',
  });

  useEffect(() => {
    if (
      !isLoadingAccountPrefs &&
      !isErrorAccountPrefs &&
      !errorAccountPrefs &&
      accountPrefsData &&
      accountPrefsData.hasOwnProperty('data')
    ) {
      const prefIds = [
        'a99d34dd-af40-45c5-b342-f594517f83bd',
        'a8612805-f592-449c-a6e5-96f7b780911c',
        'a81ce075-8b13-498f-8906-e2e7d183e38f',
        'a6a3f067-7629-465e-9487-ac2fd7a0a710',
      ];
      const showedAccountData = accountPrefsData.data.filter(
        (pref: any) => prefIds.indexOf(pref.preference_id) !== -1
      );
      setAccountPrefs(showedAccountData);
    }
  }, [
    isLoadingAccountPrefs,
    isErrorAccountPrefs,
    errorAccountPrefs,
    accountPrefsData,
  ]);

  useEffect(() => {
    return () => {
      setAllDataIsLoaded(false);
      removeAllJobs();
      removePrefs();
      removeUser();
      // removeUserAddress();
      removeUserAccounts();
      removeAccountPrefs();
      dispatch(setLastStepBreadcrumb(''));
    };
  }, [
    dispatch,
    removeAccountPrefs,
    removeAllJobs,
    removePrefs,
    removeUser,
    removeUserAccounts,
  ]);

  const showMutationMessage = (data: any) => {
    if (data && data.hasOwnProperty('error')) {
      message.error(`Error. ${data.error?.message}`);
      return;
    }
    message.success('All data saved');
  };

  const mutation = useMutation({
    mutationFn: usePostData,
    retry: 1,
    onError: () => {
      message.error('Saving data error. Network Error');
    },
    onSuccess: (data) => {
      setEnabledSaveChange(false);
      if (data && data.hasOwnProperty('error')) {
        message.error(`Error. ${data.error?.message}`);
        return;
      }
      dataToStore.current = [];
      refetchUser();
      refetchPrefs();
    },
  });

  const quantityByStatusesJobs = useCallback(
    (jobs: JobsObjT[], status: string) => {
      return jobs && jobs.length > 0
        ? jobs.filter((job: JobsObjT) => job.state_id === status).length
        : 0;
    },
    []
  );

  useEffect(() => {
    if (userData && userData.hasOwnProperty('data')) {
      setUser(userData.data);
      dispatch(
        setLastStepBreadcrumb(
          `${userData?.data?.first_name} ${userData?.data?.last_name}`
        )
      );
      if (
        userData.data.type === 'photographer' ||
        userData.data.type === 'superuser'
      ) {
        setNeedToGetNumberOfCompletedJobs(true);
      } else {
        setNeedToGetNumberOfCompletedJobs(false);
      }
    }
  }, [dispatch, userData]);

  useEffect(() => {
    if (!isLoadingJobs && allJobs && allJobs.hasOwnProperty('data')) {
      // setSumOfUnpaidJobs(reduceSumOfUnpaidJobs(data));
      const { data } = allJobs;
      setNumberOfCompletedJobs(
        quantityByStatusesJobs(
          data,
          APP_CONSTANS.FULFILMENT_STATUSES_GUID_BY_CODE.COMPLETED_JOB
        )
      );
    }
  }, [isLoadingJobs, allJobs, quantityByStatusesJobs]);

  useEffect(() => {
    if (
      userAccountsData &&
      userAccountsData.hasOwnProperty('data') &&
      userAccountsData.data.length > 0
    ) {
      const ids: any = [];
      userAccountsData.data.forEach((accountData: any) => {
        let accountIdData = {
          id: accountData.id,
          preference_id: 'a4917f4f-a949-4038-b9cb-e946ebe660b8',
          value: accountData.id,
          title: accountData.name,
        };
        ids.push(accountIdData);
      });
      setAccountIds(ids);
      setDefaultPurchaseMethod(
        userAccountsData.data[0]?.purchase_method_id || ''
      );
    }
  }, [userAccountsData]);

  useEffect(() => {
    if (!accountIds && !accountIds.length) return;
    if (!isLoadingPrefs && dataPrefs && dataPrefs.data && !errorPrefs) {
      let userLevel = '';
      if (
        user.type !== 'customer' &&
        dataPrefs &&
        dataPrefs.hasOwnProperty('data')
      ) {
        userLevel = dataPrefs.data.find(
          (pref: any) =>
            pref.preference_id === APP_CONSTANS.USER_PREFS_ID.PhotographerLevel
        ).value;
      }
      const userLevels = dataPrefs.data.filter(
        (p: { preference_id: string }) =>
          p.preference_id === APP_CONSTANS.USER_PREFS_ID.PhotographerLevel
      );
      if (userLevels && Array.isArray(userLevels) && userLevels.length > 0) {
        setUserLevelOptions(userLevels[0]?.preference?.options);
      }
      const defaultInPrefs = dataPrefs.data.find(
        (pref: any) =>
          pref.preference_id === 'a4917f4f-a949-4038-b9cb-e946ebe660b8'
      );
      if (defaultInPrefs) {
        defaultInPrefs.preference.options = accountIds;
        setDefaultAccount(defaultInPrefs);
        setDefaultAccountId(
          defaultInPrefs?.value
            ? defaultInPrefs.value
            : defaultInPrefs?.preference?.options[0]?.value
        );
      } else {
        setDefaultAccountId(accountIds[0]?.value);
      }
      setInitialValuesForm((prevState: any) => ({
        ...prevState,
        rate: 3,
        type: user.type,
        email: user.email,
        phone: user.phone,
        level: userLevel,
      }));
    }
    setMainAddressId(user.hasOwnProperty('address_id') ? user.address_id : '');
  }, [user, isLoadingPrefs, dataPrefs, errorPrefs, accountIds]);

  useEffect(() => {
    if (initialValuesForm && form && user && user.id === formattedId) {
      form.setFieldsValue(initialValuesForm);
    }
  }, [form, initialValuesForm, user, formattedId]);

  const submitPhotographerForm = useCallback(() => {
    const dataToBack: [] = dataToStore.current;
    const prefs: object[] = [];
    const accPrefs: object[] = [];
    const others: { [key: string]: any } = {
      id: formattedId,
    };
    const bulkPrefs = {
      user_id: formattedId,
      preferences: [{}],
    };
    const accBulkPrefs = {
      account_id: defaultAccountId,
      preferences: [{}],
    };
    const prefPrefix = 'pref#';
    if (dataToBack.length) {
      dataToBack.forEach((e: { [key: string]: any }) => {
        const keyName = Object.keys(e).at(0);
        if (keyName && keyName.includes(prefPrefix)) {
          const prefId = keyName.replace(prefPrefix, '');
          if (
            prefId === APP_CONSTANS.PHOTOGRAPHER_PROPS.USE_PAYPAL ||
            prefId === APP_CONSTANS.PHOTOGRAPHER_PROPS.USE_VENMO_ID ||
            prefId === APP_CONSTANS.PHOTOGRAPHER_PROPS.USE_VENMO_NO ||
            prefId === APP_CONSTANS.ACCOUNT_PREFS_CODE.DefaultPay
          ) {
            accPrefs.push({
              preference_id: prefId,
              value: e[keyName].toString(),
            });
          } else {
            prefs.push({
              preference_id: prefId,
              value: e[keyName].toString(),
            });
          }
        }
        if (keyName && !keyName.includes(prefPrefix)) {
          others[keyName] = e[keyName];
          if (keyName === 'level') {
            prefs.push({
              preference_id: 'a3cc8111-d107-49a8-a5e8-4bce9b1cf341',
              value: e[keyName].toString(),
            });
            delete others['level'];
          }
        }
      });
      let messageShown = false;
      if (prefs.length) {
        bulkPrefs.preferences = prefs;
        mutation.mutate(
          {
            data: bulkPrefs,
            token: token,
            otherProps: 'user_preferences/bulk',
          },
          {
            onSuccess: (data) => {
              if (!messageShown) showMutationMessage(data);
              messageShown = true;
            },
          }
        );
      }
      if (accPrefs.length) {
        accBulkPrefs.preferences = accPrefs;
        mutation.mutate(
          {
            data: accBulkPrefs,
            token: token,
            otherProps: 'account_preferences/bulk',
          },
          {
            onSuccess: (data) => {
              if (!messageShown) showMutationMessage(data);
              messageShown = true;
            },
          }
        );
      }
      if (Object.keys(others).length > 1) {
        mutation.mutate(
          {
            data: others,
            token: token,
            otherProps: 'users',
            method: 'PUT',
          },
          {
            onSuccess: (data) => {
              if (!messageShown) showMutationMessage(data);
              messageShown = true;
            },
          }
        );
      }
    }
  }, [defaultAccountId, formattedId, mutation, token]);

  const defaultData = useMemo(() => {
    return {
      first_name: user ? user.first_name : '',
      middle_name: user ? user.middle_name : '',
      last_name: user ? user.last_name : '',
    };
  }, [user]);

  useEffect(() => {
    const allDataIsLoadedSuccessfully =
      userData &&
      userData.data &&
      !isLoadingUser &&
      !isErrorUser &&
      userAccountsData &&
      userAccountsData.data &&
      !isLoadingUserAccounts &&
      !isErrorUserAccounts &&
      user &&
      user.id === formattedId &&
      dataPrefs &&
      dataPrefs.data &&
      dataPrefs.data.length > 0 &&
      !isLoadingPrefs &&
      !isErrorPrefs;
    setAllDataIsLoaded(allDataIsLoadedSuccessfully);
    // if (user && user.type === 'photographer' && !accountPrefs) {
    //   setAllDataIsLoaded(false);
    // } else {
    //   setAllDataIsLoaded(allDataIsLoadedSuccessfully);
    // }
  }, [
    userData,
    isLoadingUser,
    isErrorUser,
    userAccountsData,
    isLoadingUserAccounts,
    isErrorUserAccounts,
    user,
    formattedId,
    dataPrefs,
    isLoadingPrefs,
    isErrorPrefs,
    accountPrefs,
  ]);

  return allDataIsLoaded ? (
    <MainContainer expanded>
      <article className={styles.article}>
        <Form
          form={form}
          layout="vertical"
          onFinish={submitPhotographerForm}
          initialValues={initialValuesForm}
          onFieldsChange={(changedFields) => {
            handleChangeFields(changedFields, dataToStore.current);
            setEnabledSaveChange(true);
          }}
        >
          <div className={styles.formHeader}>
            <Button
              htmlType="button"
              onClick={resetForm}
              ghost={!enabledSaveChanges}
              disabled={!enabledSaveChanges}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              disabled={!enabledSaveChanges}
            >
              Save changes
            </Button>
            {user && (
              <Header
                type={user.type}
                email={user.email}
                userId={formattedId}
                status={user.status}
                isDeleted={user.hasOwnProperty('deleted_at')}
                dataPrefs={dataPrefs}
                setWithDeleted={setWithDeleted}
                refetchUser={refetchUser}
                refetchPrefs={refetchPrefs}
              />
            )}
          </div>

          <div className={styles.formContent}>
            <div className={styles.article__left}>
              <UserHeader
                name={`${user.first_name} ${
                  user.hasOwnProperty('middle_name') ? user.middle_name : ''
                } ${user.last_name}`}
                status={user.status}
                type={user.type}
                email={user.email}
                workNumber={user.phone}
                setShowedModalName={setShowedModalName}
                defaultAccount={defaultAccount}
                deleted_at={user.hasOwnProperty('deleted_at')}
                userLevelOptions={userLevelOptions}
              />

              <UserTables
                listOfAccounts={userAccountsData}
                type={user.type}
                mainAddressId={mainAddressId}
                userCrearedAt={user.created_at}
                quantityByStatusesJobs={quantityByStatusesJobs}
                refetchUser={refetchUser}
                // refetchUserAddress={refetchUserAddress}
                dataPrefs={dataPrefs}
                id={formattedId}
                allJobs={allJobs}
                isLoadingJobs={isLoadingJobs}
                defaultAccountId={defaultAccountId}
                defaultPurchaseMethod={defaultPurchaseMethod}
              />
            </div>

            <UserPrefs
              prefs={dataPrefs?.data}
              extraPrefs={accountPrefs}
              createdAt={user?.created_at}
              updatedAt={user?.updated_at}
              deletedAt={user?.deleted_at}
              lastLogin={user?.last_login}
              industry={user?.industry?.name}
              numberOfCompletedJobs={numberOfCompletedJobs}
              type={user.type}
              userId={formattedId}
              pageName="user"
              defaultAccId={user?.account?.id}
              defaultAccName={user?.account?.name}
              userAccounts={userAccountsData?.data}
            />
          </div>
        </Form>
      </article>

      <ModalChangeName
        showedModalName={showedModalName}
        setShowedModalName={setShowedModalName}
        defaultData={defaultData}
        userId={user.id}
        refetchUser={refetchUser}
        token={token}
        setEnabledSaveChange={setEnabledSaveChange}
      />
    </MainContainer>
  ) : (
    <Loader />
  );
};
