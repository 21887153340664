import { UserOutlined } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import { Button, MenuProps } from 'antd';
import { Avatar, Dropdown } from 'antd';
import { queryNames } from 'api/queryNames';
import useGetData from 'api/useGetData';
import { useGetAuthUser } from 'hooks/api/user/useGetAuthUser';
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { APP_CONSTANS } from 'shared/constants';
import { signOutUser } from 'shared/utils/api/signOutUser';
import { setManualLogout, setVitual } from 'store/auth/actions';
import styles from './styles.module.scss';
import { MainHeaderT } from './types';
import { debounce } from 'lodash';
import { ModalUserSettings } from 'components/modals/ModalUserSettings';
import avatarImg from 'shared/images/stich.jpg';
import { Quiz } from './Quiz';

export const UserMenu: FunctionComponent<MainHeaderT> = () => {
  const logout = useCallback(() => {
    setIsLogout(true);
  }, []);

  const showSettingsModal = useCallback(() => {
    setShowSettings(true);
  }, []);

  const homeItems: MenuProps['items'] = useMemo(
    () => [
      {
        key: 'settings',
        label: 'Settings',
        onClick: () => showSettingsModal(),
      },
      {
        key: 'logout',
        label: 'Logout',
        onClick: () => logout(),
      },
    ],
    [showSettingsModal, logout]
  );

  const virtualLogoutItem: MenuProps['items'] = useMemo(
    () => [
      {
        key: 'virtualLogout',
        label: 'Virtual Logout',
        onClick: () => setIsVirtualLogout(true),
      },
    ],
    []
  );

  const dispatch = useDispatch();
  const [isLogout, setIsLogout] = useState(false);
  const [isVirtualLogout, setIsVirtualLogout] = useState(false);
  const [items, setItems] = useState([...homeItems]);
  const token = useSelector((state: any) => state.auth.token);
  const isVitual = useSelector((state: any) => state.auth.isVirtual);
  const [width, setWidth] = useState(window.innerWidth);
  const [showSettings, setShowSettings] = useState<boolean>(false);

  useEffect(() => {
    const handleResize = debounce(() => setWidth(window.innerWidth), 100);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const { data: dataVirtualLogout } = useQuery({
    queryKey: [queryNames.AUTH_virtualLogout],
    meta: {
      token: localStorage.getItem(APP_CONSTANS.LS_VL_TOKEN),
      getAllData: true,
    },
    queryFn: useGetData,
    enabled: isVirtualLogout,
    onSuccess(data) {
      dispatch(setVitual(false));
      localStorage.removeItem(APP_CONSTANS.LS_VL_TOKEN);
      if (data?.data?.access_token) {
        //TODO set new token
      }
    },
  });

  const {
    data: userInfo,
    isLoading: userInfoIsLoading,
    isError: userInfoIsError,
  } = useGetAuthUser();

  const { first_name: firstName, last_name: lastName } = userInfo?.data || {};

  const fullName = useMemo(
    () => (firstName && lastName ? `${firstName} ${lastName}` : null),
    [firstName, lastName]
  );

  useEffect(() => {
    if (isLogout) {
      const logoutFetch = async () => {
        try {
          const response = await fetch(queryNames.AUTH_logout, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          if (!response.ok) throw new Error(response.statusText);
          signOutUser(true);
          dispatch(setManualLogout(true));
        } catch (error) {
          console.log(error);
          signOutUser(true);
          dispatch(setManualLogout(true));
        }
      };
      logoutFetch();
    }
  }, [dispatch, isLogout, token]);

  useEffect(() => {
    if (dataVirtualLogout) {
      console.log('dataVirtualLogout', dataVirtualLogout);
    }
  }, [dataVirtualLogout]);

  useEffect(() => {
    // console.log('asd', isVitual);
    isVitual
      ? setItems([...homeItems, ...virtualLogoutItem])
      : setItems([...homeItems]);
  }, [isVitual, homeItems, virtualLogoutItem]);

  // TODO: Move error handling in separate interceptor
  if (userInfoIsError) {
    signOutUser();
  }

  return userInfoIsLoading ? (
    <></>
  ) : (
    <>
      {(userInfo?.data.id === 'e16ffd8f-8832-4edd-b8f2-a4f6e9f8de64' ||
        userInfo?.data.id === '584100a8-77d5-4373-87ef-5faa8a030401') &&
        window.location.href.indexOf('/fulfilments/') > -1 && (
          <Quiz userId={userInfo?.data.id} />
        )}
      <Dropdown
        className={styles.profile_block}
        menu={{ items }}
        placement="bottom"
        trigger={['click']}
      >
        <Button type="link" className={styles.dropdownButton}>
          {fullName && width > 767 && (
            <p className={styles.profile_block__name} data-e2e="userProfile">
              Hello, <span>{fullName}</span>
            </p>
          )}
          <Avatar
            className={styles.profile_block__avatar}
            size="large"
            icon={<UserOutlined />}
            {...(userInfo?.data.id === 'e16ffd8f-8832-4edd-b8f2-a4f6e9f8de64'
              ? { src: avatarImg }
              : {})}
          />
        </Button>
      </Dropdown>

      {showSettings && (
        <ModalUserSettings
          visibleModal={showSettings}
          setVisibleModal={setShowSettings}
        />
      )}
    </>
  );
};
