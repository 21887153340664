import { FunctionComponent, useCallback, useRef, useState } from 'react';
import { Button, Dropdown, MenuProps, message } from 'antd';
import { useSelector } from 'react-redux';
import styles from './styles.module.scss';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { usePostData } from '../../../../../../../../../api/usePostData';
import { queryNames } from 'api/queryNames';
import { tablesNames } from 'api/tablesNames';
import { defaultEnabledSettings } from './defaultEnableSettings';
import { hideInSetting } from './hideInSettings';
import clsx from 'clsx';
import { PurchaseMethodsTable } from 'components/ui_components/PurchaseMethodsTable';
import { APP_CONSTANS } from 'shared/constants';
import { PlusOutlined } from '@ant-design/icons';
import { ModalPurchaseMethod } from 'components/modals/ModalPurchaseMethod';

export const TabPurchaseMethods: FunctionComponent<any> = ({
  userId,
  defaultPurchaseMethod,
}) => {
  const queryClient: any = useQueryClient();
  const currentPurchase = useRef<any>();
  const token = useSelector((state: any) => state.auth.token);
  const [visibleModalAdd, setVisibleModalAdd] = useState<boolean>(false);
  const [visibleModalEdit, setVisibleModalEdit] = useState<boolean>(false);

  const meta = {
    additionalSearchParams: `?user_ids=${userId}`,
    paymentsApi: true,
    getAllData: true,
  };

  const { mutate } = useMutation({
    mutationFn: usePostData,
    retry: 1,
    onError: () => {
      message.error('Network Error');
    },
    onSuccess: (data, variables) => {
      if (data && data.hasOwnProperty('error')) {
        message.error(`Error. ${data.error?.message}`);
        return;
      } else {
        queryClient.invalidateQueries(
          `${APP_CONSTANS.BASE_API_URL_V2}/${queryNames.PurchaseMethods}`
        );
        if (variables?.otherProps?.includes(queryNames.ACCOUNTS_ALL)) {
          message.success('Default Purchase method was successfully changed');
        }
        if (
          variables?.otherProps?.includes(
            queryNames.CLEANER_RESTORE_PURCHASE_METHOD
          )
        ) {
          message.success('Purchase method was successfully restored');
        }
        if (
          variables?.otherProps?.includes(
            queryNames.CLEANER_SOFT_DELETE_PURCHASE_METHOD
          )
        ) {
          message.success('Purchase method was successfully deleted');
          if (data?.data?.id === defaultPurchaseMethod) {
            message.warning(
              'Default method deleted but it is required to create Purchases',
              10
            );
          }
        }
      }
    },
  });

  const addPurchase = () => {
    setVisibleModalAdd(true);
  };

  const editPurchase = () => {
    setVisibleModalEdit(true);
  };

  const setAsDefault = useCallback(() => {
    const dataToBack = {
      id: currentPurchase.current.account_id,
      purchase_method_id: currentPurchase.current.id,
    };
    mutate({
      data: dataToBack,
      token,
      otherProps: queryNames.ACCOUNTS_ALL,
      method: 'PUT',
    });
  }, [mutate, token]);

  const restorePurchase = useCallback(() => {
    const dataToBack = {
      id: currentPurchase.current.id,
    };
    mutate({
      data: dataToBack,
      token,
      otherProps: queryNames.CLEANER_RESTORE_PURCHASE_METHOD,
      method: 'POST',
    });
  }, [mutate, token]);

  const deletePurchase = useCallback(() => {
    const dataToBack = {
      id: currentPurchase.current.id,
    };
    mutate({
      data: dataToBack,
      token,
      otherProps: queryNames.CLEANER_SOFT_DELETE_PURCHASE_METHOD,
      method: 'POST',
    });
  }, [mutate, token]);

  const toolbarRender = [
    <Button
      key="button"
      type="default"
      icon={<PlusOutlined />}
      onClick={addPurchase}
    >
      Add new Method
    </Button>,
  ];

  const itemsForDeleted: MenuProps['items'] = [
    {
      key: 'Restore_Deleted',
      label: (
        <Button
          type="text"
          onClick={(event: React.MouseEvent<HTMLElement>) => {
            event.stopPropagation();
            restorePurchase();
          }}
        >
          Restore
        </Button>
      ),
    },
  ];

  const items: MenuProps['items'] = [
    {
      key: 'Edit',
      label: (
        <Button type="text" onClick={editPurchase}>
          Edit
        </Button>
      ),
    },
    {
      key: 'Set_as_Default',
      label: (
        <Button
          type="text"
          onClick={(event: React.MouseEvent<HTMLElement>) => {
            event.stopPropagation();
            setAsDefault();
          }}
        >
          Set as Default
        </Button>
      ),
    },
    {
      key: 'Soft_Delete',
      label: (
        <Button
          type="text"
          danger
          onClick={(event: React.MouseEvent<HTMLElement>) => {
            event.stopPropagation();
            deletePurchase();
          }}
        >
          Soft Delete
        </Button>
      ),
    },
  ];

  const actionsColumn = {
    title: 'Action',
    width: 100,
    fixed: 'right',
    hideInSetting: true,
    render: (record: any) => (
      <Dropdown
        key="dropdownPurchaseMethod"
        trigger={['click']}
        menu={{
          items: !record?.deleted_at ? items : itemsForDeleted,
        }}
        overlayClassName={clsx(
          record.id === defaultPurchaseMethod && styles.hideDefault,
          styles.action
        )}
      >
        <Button
          onClick={(event: React.MouseEvent<HTMLElement>) => {
            event.stopPropagation();
            currentPurchase.current = record;
          }}
          type="text"
        >
          ...
        </Button>
      </Dropdown>
    ),
  };

  return (
    <>
      <PurchaseMethodsTable
        defaultEnabledSettings={defaultEnabledSettings}
        queryKey={[queryNames.PurchaseMethods, userId]}
        meta={meta}
        headerName={tablesNames.PurchaseMethodsHeader}
        toolbar={null}
        toolBarRender={toolbarRender}
        actionsColumn={actionsColumn}
        tableName={tablesNames.PurchaseMethodsCol}
        hideInSetting={hideInSetting}
        defaultPurchaseId={defaultPurchaseMethod}
        resetAllFiltering={true}
      />

      {visibleModalAdd && (
        <ModalPurchaseMethod
          visible={visibleModalAdd}
          setVisible={setVisibleModalAdd}
          userId={userId}
        />
      )}

      {visibleModalEdit && (
        <ModalPurchaseMethod
          visible={visibleModalEdit}
          setVisible={setVisibleModalEdit}
          userId={userId}
          data={currentPurchase.current}
        />
      )}
    </>
  );
};
